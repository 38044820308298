import React, { useState } from "react"
import axios from 'axios'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../../components/seo"
import Img from "../../../components/image-transform"

const CombatChargebacks = () => {
  const [onSubmit, setOnSubmit] = useState({message: "Download now", isSubmitted: false });
  const [emailAdd, hasEmail] = useState('')
  const [name, hasName] = useState('')
  const [company, hasCompany] = useState('')
  const [error, isError] = useState()
  const [disabled, isDisabled] = useState(true)

  const handleChange = event => {
    hasEmail(event.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }

  const handleChangeName = eventi => {
    hasName(eventi.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  const handleChangeCompany = eventj => {
    hasCompany(eventj.target.value)
    isDisabled(false)
    setOnSubmit({message: "Download now", isSubmitted: false });
  }
  
  const handleSubmit = event => {
    event.preventDefault();

    const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const result = pattern.test(emailAdd);

    if (result === true) {
      isError(false)
      isDisabled(true)
      setOnSubmit({message: "Sending...", isSubmitted: false });

      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/https://api.mailerlite.com/api/v2/groups/103047540/subscribers`,
        headers: { 'content-type': 'application/json', 'X-MailerLite-ApiKey': 'f13b6f484a31a41bbdefdd972b1d6099'  },
        data: {
          email: emailAdd,
          name: name,
          type: 'active',
          fields: {
            company: company
          }
        }
      }).then(res=> {
          setOnSubmit({message: "Thanks! We've emailed your eBook to you.", isSubmitted: true });
          hasEmail('');
          hasName('');
          hasCompany('');
      }).catch((error) => {
        setOnSubmit({message: "Ops! Please try again.", isSubmitted: false });
      })
    } else (
      isError(true)
    )
  };

  return (
    <Layout>
      <SEO 
        title="Combat Chargebacks in your Subscription Business | Free eBook" 
        description="Reduce chargebacks to increase the profitability of your subscription business with our free eBook - download it today to improve your recurring payments revenue." 
        url="https://www.billsby.com/resources/guides/combat-chargebacks-in-your-subscription-business"
      />

      <div className="ebook subscription">
        <div className="section-hero">
          <div className="container container-flex">
            <div className="section-content">
              <h1 className="section-heading">Combat chargebacks in your subscription business.</h1>
              <p className="section-text">Revenue loss due to chargebacks can often not be recovered, so you must do everything you can to prevent them from happening in the first place. Operating a business relying on recurring revenue, you’re more exposed to the risk of chargebacks, which can not only diminish your revenue but also take up valuable time to challenge and process. So what exactly are chargebacks and why do they matter? And why might someone raise a dispute?</p>
              <p className="section-text"> We’ll go through the most common ones in this e-book and talk more about what you can do to reduce legitimate chargebacks too.</p>

              <a className="btn-orange" href="#ebookForm">Download free eBook</a>
            </div>
            <div className="section-image">
              <Img filename="ebook-combat.png" className="main-img" alt="Combat chargebacks in your subscription business" />
            </div>
          </div>
        </div>
        <div className="section-ebook w-full">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">Making charges immediately apparent to customers</h2>
              <p className="section-text">Poor merchant descriptors will lead to disputes. The situations play out as follows: the customer reviews their bank statement, sees a transaction they don’t remember purchasing and aren’t given any helpful information in the descriptor, so they contact their bank to dispute the transaction. This is one of the most common reasons for chargebacks, and also the most unnecessary and avoidable. </p>
              
              <p className="section-text"> There are several great practices that your business should keep in mind. The most basic is displaying a recognizable company name, as a minimum. You could also include the name of your product or service and any additional information to help them understand exactly what this charge is for. You’ve got 20 characters, so make them count. </p>
              
              <p className="section-text">If you want to go one step further, you could even add a URL to your descriptor, linking out to a webpage with all the information the customer needs about what the charge is for, contact details so they can get in touch with any questions or queries, and information on your refund policy, in case they’ve changed their mind about the purchase. </p>
              
              <p className="section-text"> A great example of this in practice is 37signals, the company behind the popular product, Basecamp. In their statement descriptor, they include the URL: <a href="http://37signals.com/charge" rel="noreferrer" target="_blank">37signals.com/charge</a>. </p>
              
              <p className="section-text">Clicking through to the link or typing this directly into your browser you’ll be directed to a webpage with a whole host of useful information, including the full list of products available, useful FAQs, contact information and their refund policy. Having this information available so easily to your customers will not only reduce the number of chargebacks you’ll receive, but you’ll also be able to use this as evidence when you do get chargebacks that you need to dispute.</p>
            </div>
          </div>
        </div>
        <div className="section-ebook w-full">
          <div className="container container-flex">
            <div className="ebook-content">
              <h2 className="section-heading">Providing service at the point of invoicing</h2>
              <p className="section-text">It’s a general rule in customer service that your contact information should be easy to find. This is even more important when it comes to billing and invoicing. Your phone number, support emails, and contact forms should be accessible from your emails, invoices and also from your website or app. Whether it’s in the footer, or you include a link straight to a contact form, it should be easier for a customer to contact you than it is to contact the issuing bank. </p>
              
              <p className="section-text">Once the customer has contacted you directly, you need to make sure that you’re ready to respond to them promptly. If a customer is querying a charge, they don’t want to wait a long time for a response. They may already be worried and not receiving an adequate response, or not hearing from you at all will only exacerbate their concerns. It’s at this point that the customer will go over your head and contact their bank to dispute the charge directly.</p>
            </div>
          </div>
        </div>
        <div id="ebookForm" className="ebook-form">
          <div className="container">
            <div className="form-wrapper container-flex">
              <form>
                <h2 className="section-heading">Download our free eBook today to learn the rest of our ways to combat chargebacks in your business</h2>

                <div className="form-group">
                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter you name" name="name" value={name} onChange={handleChangeName} />
                  
                  <input type="email" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your email address" name="emailAdd" value={emailAdd} onChange={handleChange}/>

                  <input type="text" className={`input-field ${error ? 'error' : ''}`} placeholder="Enter your company name" name="companyName" value={company} onChange={handleChangeCompany}/>

                  <button type="submit" className={ `btn-blue ${onSubmit.isSubmitted ? 'btn-submitted' : ''}` } onClick={ handleSubmit } disabled={disabled}>{ onSubmit.message }</button>

                  <p className="section-text">You'll be signed up for marketing communications from Billsby. We respect your <Link to="/privacy">privacy</Link>.</p>
                </div>

              </form>
              <div className="brochure-holder">
                <Img filename="ebook-combat.png" className="main-img" alt="Combat chargebacks in your subscription business" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CombatChargebacks